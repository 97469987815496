/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  ItemControllerHelper,
  ItemControllerHelperInterface,
} from "../ItemControllerHelper";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ErrorNotOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "LengthsMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "UnionItemNotFound",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "unionConfigId",
        type: "uint256",
      },
    ],
    name: "UnknownUnionConfig",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "address",
        name: "keyPassItem",
        type: "address",
      },
    ],
    name: "UserHasNoKeyPass",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "countTokens",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "requiredCountTokens",
        type: "uint256",
      },
    ],
    name: "WrongListUnionItemTokens",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroValueNotAllowed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
    ],
    name: "RemoveUnionConfig",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "keyPassItem",
        type: "address",
      },
    ],
    name: "SetAugmentationProtectiveItem",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "count",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "itemToMint",
        type: "address",
      },
    ],
    name: "SetUnionConfig",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "keyPassItem",
        type: "address",
      },
    ],
    name: "SetUnionKeyPass",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "count",
        type: "uint256[]",
      },
      {
        internalType: "address",
        name: "itemToMint",
        type: "address",
      },
    ],
    name: "addUnionConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getAugmentationProtectiveItem",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "globalParam",
        type: "uint8",
      },
    ],
    name: "getGlobalParamValue",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "keyPassItem",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listUnionConfigsLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        internalType: "uint256[][]",
        name: "itemIds",
        type: "uint256[][]",
      },
    ],
    name: "prepareToCombine",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
    ],
    name: "removeUnionConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "setAugmentationProtectiveItem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "setUnionKeyPassItem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
    ],
    name: "unionConfig",
    outputs: [
      {
        components: [
          {
            internalType: "uint96",
            name: "configId",
            type: "uint96",
          },
          {
            internalType: "address",
            name: "itemToMint",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "items",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "count",
            type: "uint256[]",
          },
        ],
        internalType: "struct IItemControllerHelper.UnionConfig",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "unionConfigIdByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "count",
        type: "uint256[]",
      },
      {
        internalType: "address",
        name: "itemToMint",
        type: "address",
      },
    ],
    name: "updateUnionConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class ItemControllerHelper__factory {
  static readonly abi = _abi;
  static createInterface(): ItemControllerHelperInterface {
    return new Interface(_abi) as ItemControllerHelperInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): ItemControllerHelper {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as ItemControllerHelper;
  }
}
