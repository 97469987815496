/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  GuildController,
  GuildControllerInterface,
} from "../GuildController";

const _abi = [
  {
    inputs: [],
    name: "AlreadyGuildMember",
    type: "error",
  },
  {
    inputs: [],
    name: "EmptyNameNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotShelterController",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorOnlyEoa",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorPaused",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "right",
        type: "uint256",
      },
    ],
    name: "GuildActionForbidden",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
    ],
    name: "GuildHasMaxLevel",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildSize",
        type: "uint256",
      },
    ],
    name: "GuildHasMaxSize",
    type: "error",
  },
  {
    inputs: [],
    name: "GuildRequestNotActive",
    type: "error",
  },
  {
    inputs: [],
    name: "GuildRequestNotAvailable",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "LengthsMismatch",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "fee",
        type: "uint8",
      },
    ],
    name: "MaxFee",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "fee",
        type: "uint8",
      },
    ],
    name: "MinFee",
    type: "error",
  },
  {
    inputs: [],
    name: "NameTaken",
    type: "error",
  },
  {
    inputs: [],
    name: "NotAdminCannotAddMemberWithNotZeroRights",
    type: "error",
  },
  {
    inputs: [],
    name: "NotGuildMember",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "ReentrancyGuardReentrantCall",
    type: "error",
  },
  {
    inputs: [],
    name: "SecondGuildAdminIsNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "TooBigName",
    type: "error",
  },
  {
    inputs: [],
    name: "TooLongDescription",
    type: "error",
  },
  {
    inputs: [],
    name: "TooLongString",
    type: "error",
  },
  {
    inputs: [],
    name: "TooLongUrl",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongGuild",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongSymbolsInTheName",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroAddress",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newUser",
        type: "address",
      },
    ],
    name: "AddToGuild",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "guildBank",
        type: "address",
      },
    ],
    name: "GuildBankDeployed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "urlLogo",
        type: "string",
      },
    ],
    name: "GuildCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "newDescription",
        type: "string",
      },
    ],
    name: "GuildDescriptionChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "newLevel",
        type: "uint8",
      },
    ],
    name: "GuildLevelUp",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "newLogoUrl",
        type: "string",
      },
    ],
    name: "GuildLogoChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "newName",
        type: "string",
      },
    ],
    name: "GuildRename",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "msgSender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "userMessage",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "depositAmount",
        type: "uint256",
      },
    ],
    name: "GuildRequestRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "msgSender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildRequestId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "newStatus",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "GuildRequestStatusChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId1",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId2",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "peace",
        type: "bool",
      },
    ],
    name: "SetGuildRelation",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "msgSender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "guildBank",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "TopUpGuildBank",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "TransferFromGuildBank",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "nfts",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "TransferNftFromGuildBank",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildRequestId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maskRights",
        type: "uint256",
      },
    ],
    name: "acceptGuildRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "userMessage",
        type: "string",
      },
    ],
    name: "addGuildRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildRequestId",
        type: "uint256",
      },
    ],
    name: "cancelGuildRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newDescription",
        type: "string",
      },
    ],
    name: "changeDescription",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newLogoUrl",
        type: "string",
      },
    ],
    name: "changeLogo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maskRights",
        type: "uint256",
      },
    ],
    name: "changeRoles",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "right",
        type: "uint256",
      },
    ],
    name: "checkPermissions",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rights",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "urlLogo",
        type: "string",
      },
      {
        internalType: "uint8",
        name: "toHelperRatio",
        type: "uint8",
      },
    ],
    name: "createGuild",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getBaseFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "getGuildBank",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "getGuildByName",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "getGuildData",
    outputs: [
      {
        internalType: "string",
        name: "guildName",
        type: "string",
      },
      {
        internalType: "string",
        name: "urlLogo",
        type: "string",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "guildLevel",
        type: "uint8",
      },
      {
        internalType: "uint64",
        name: "pvpCounter",
        type: "uint64",
      },
      {
        internalType: "uint256",
        name: "toHelperRatio",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "getGuildDescription",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "paramId",
        type: "uint256",
      },
    ],
    name: "getGuildParamValue",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildRequestId",
        type: "uint256",
      },
    ],
    name: "getGuildRequest",
    outputs: [
      {
        internalType: "uint8",
        name: "status",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "userMessage",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "getGuildRequestDepositAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getRights",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserActiveGuildRequests",
    outputs: [
      {
        internalType: "uint256[]",
        name: "guildRequestIds",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserPvpPoints",
    outputs: [
      {
        internalType: "uint64",
        name: "capacityPvpPoints",
        type: "uint64",
      },
      {
        internalType: "uint64",
        name: "spentPvpPoints",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "guildLevelUp",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "guildMembers",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "guildToShelter",
    outputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "rightBit",
        type: "uint8",
      },
    ],
    name: "hasPermission",
    outputs: [
      {
        internalType: "bool",
        name: "userHasTheRight",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint64",
        name: "value",
        type: "uint64",
      },
    ],
    name: "incPvpCounter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "guildId2",
        type: "uint256",
      },
    ],
    name: "isPeacefulRelation",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "status",
        type: "uint8",
      },
    ],
    name: "listGuildRequests",
    outputs: [
      {
        internalType: "uint256[]",
        name: "guildRequestIds",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "memberOf",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "bid",
        type: "uint256",
      },
    ],
    name: "payForAuctionBid",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
    ],
    name: "payFromBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "shelterPrice",
        type: "uint256",
      },
    ],
    name: "payFromGuildBank",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildRequestId",
        type: "uint256",
      },
    ],
    name: "rejectGuildRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "userToRemove",
        type: "address",
      },
    ],
    name: "removeGuildMember",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newGuildName",
        type: "string",
      },
    ],
    name: "rename",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
    ],
    name: "setBaseFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "setGuildRequestDepositAmount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "capacityPvpPoints",
        type: "uint64",
      },
      {
        internalType: "address[]",
        name: "users",
        type: "address[]",
      },
    ],
    name: "setPvpPointsCapacity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "otherGuildId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "peace",
        type: "bool",
      },
    ],
    name: "setRelation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "shelterAuction_",
        type: "address",
      },
    ],
    name: "setShelterAuctionController",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "shelterController_",
        type: "address",
      },
    ],
    name: "setShelterController",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "value",
        type: "uint8",
      },
    ],
    name: "setToHelperRatio",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "shelterAuctionController",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "shelterController",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "topUpGuildBank",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "address[]",
        name: "recipients",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
    ],
    name: "transferMulti",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "address[]",
        name: "nfts",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
    ],
    name: "transferNftMulti",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "priceInPvpPoints",
        type: "uint64",
      },
    ],
    name: "usePvpPoints",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class GuildController__factory {
  static readonly abi = _abi;
  static createInterface(): GuildControllerInterface {
    return new Interface(_abi) as GuildControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): GuildController {
    return new Contract(address, _abi, runner) as unknown as GuildController;
  }
}
