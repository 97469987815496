/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  ItemController,
  ItemControllerInterface,
} from "../ItemController";

const _abi = [
  {
    inputs: [],
    name: "AlreadyInitialized",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotStoryController",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorPaused",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "int32",
        name: "min",
        type: "int32",
      },
      {
        internalType: "int32",
        name: "max",
        type: "int32",
      },
    ],
    name: "IncorrectMinMaxAttributeRange",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "kind",
        type: "uint8",
      },
    ],
    name: "IncorrectOtherItemTypeKind",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "int256",
        name: "value",
        type: "int256",
      },
    ],
    name: "IntOutOfRange",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "ItemMetaTypeChanged",
    type: "error",
  },
  {
    inputs: [],
    name: "ItemTypeChanged",
    type: "error",
  },
  {
    inputs: [],
    name: "LengthsMismatch",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "NotEOA",
    type: "error",
  },
  {
    inputs: [],
    name: "NotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitialized",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "NotZeroOtherItemMetaType",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "length",
        type: "uint256",
      },
    ],
    name: "OutOfBounds",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "TooHighValue",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongWayToRegisterItem",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroItemMetaType",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroLevel",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroValue",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "msgSender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[][]",
        name: "itemIds",
        type: "uint256[][]",
      },
      {
        indexed: false,
        internalType: "address",
        name: "mintedItem",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "mintedItemId",
        type: "uint256",
      },
    ],
    name: "CombineItems",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "Destroyed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "helper",
        type: "address",
      },
    ],
    name: "ItemControllerHelper",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "uint8",
                name: "itemMetaType",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "itemLevel",
                type: "uint8",
              },
              {
                internalType: "enum IItemController.ItemType",
                name: "itemType",
                type: "uint8",
              },
              {
                internalType: "uint16",
                name: "baseDurability",
                type: "uint16",
              },
              {
                internalType: "uint8",
                name: "defaultRarity",
                type: "uint8",
              },
              {
                internalType: "uint32",
                name: "manaCost",
                type: "uint32",
              },
              {
                internalType: "uint8",
                name: "minRandomAttributes",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "maxRandomAttributes",
                type: "uint8",
              },
              {
                components: [
                  {
                    internalType: "int32",
                    name: "strength",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "dexterity",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "vitality",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "energy",
                    type: "int32",
                  },
                ],
                internalType: "struct IStatController.CoreAttributes",
                name: "requirements",
                type: "tuple",
              },
            ],
            internalType: "struct IItemController.ItemMeta",
            name: "itemMeta",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "augmentToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "augmentAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "mins",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "maxs",
                type: "int32[]",
              },
              {
                internalType: "uint32[]",
                name: "chances",
                type: "uint32[]",
              },
            ],
            internalType: "struct IItemController.ItemGenerateInfo",
            name: "commonAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "values",
                type: "int32[]",
              },
            ],
            internalType: "struct IGOC.AttributeGenerateInfo",
            name: "consumableAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint32",
                name: "level",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "experience",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "life",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "mana",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "lifeChances",
                type: "uint32",
              },
            ],
            internalType: "struct IStatController.ChangeableStats",
            name: "consumableStats",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "mins",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "maxs",
                type: "int32[]",
              },
              {
                internalType: "uint32[]",
                name: "chances",
                type: "uint32[]",
              },
            ],
            internalType: "struct IItemController.ItemGenerateInfo",
            name: "casterAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "mins",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "maxs",
                type: "int32[]",
              },
              {
                internalType: "uint32[]",
                name: "chances",
                type: "uint32[]",
              },
            ],
            internalType: "struct IItemController.ItemGenerateInfo",
            name: "targetAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "enum IItemController.AttackType",
                name: "aType",
                type: "uint8",
              },
              {
                internalType: "int32",
                name: "min",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "max",
                type: "int32",
              },
              {
                components: [
                  {
                    internalType: "int32",
                    name: "strength",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "dexterity",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "vitality",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "energy",
                    type: "int32",
                  },
                ],
                internalType: "struct IStatController.CoreAttributes",
                name: "attributeFactors",
                type: "tuple",
              },
            ],
            internalType: "struct IItemController.AttackInfo",
            name: "genAttackInfo",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "consumableActionMask",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct IItemController.RegisterItemParams",
        name: "info",
        type: "tuple",
      },
    ],
    name: "ItemRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "ItemRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        components: [
          {
            internalType: "uint8",
            name: "itemMetaType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "itemLevel",
            type: "uint8",
          },
          {
            internalType: "enum IItemController.ItemType",
            name: "itemType",
            type: "uint8",
          },
          {
            internalType: "uint16",
            name: "baseDurability",
            type: "uint16",
          },
          {
            internalType: "uint8",
            name: "defaultRarity",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "manaCost",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "minRandomAttributes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "maxRandomAttributes",
            type: "uint8",
          },
          {
            components: [
              {
                internalType: "int32",
                name: "strength",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "dexterity",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "vitality",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "energy",
                type: "int32",
              },
            ],
            internalType: "struct IStatController.CoreAttributes",
            name: "requirements",
            type: "tuple",
          },
        ],
        indexed: false,
        internalType: "struct IItemController.ItemMeta",
        name: "meta",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "packedItemMetaData",
        type: "bytes",
      },
    ],
    name: "OtherItemRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "OtherItemRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "consumedItemId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "protectiveItem",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "protectiveItemId",
            type: "uint256",
          },
        ],
        internalType: "struct IItemController.AugmentOptParams",
        name: "opt",
        type: "tuple",
      },
    ],
    name: "augment",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "augmentInfo",
    outputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "casterAttributes",
    outputs: [
      {
        internalType: "int32[]",
        name: "values",
        type: "int32[]",
      },
      {
        internalType: "uint8[]",
        name: "ids",
        type: "uint8[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "configId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        internalType: "uint256[][]",
        name: "itemIds",
        type: "uint256[][]",
      },
    ],
    name: "combineItems",
    outputs: [
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "consumableActionMask",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "consumableAttributes",
    outputs: [
      {
        internalType: "int32[]",
        name: "values",
        type: "int32[]",
      },
      {
        internalType: "uint8[]",
        name: "ids",
        type: "uint8[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "consumableStats",
    outputs: [
      {
        components: [
          {
            internalType: "uint32",
            name: "level",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "experience",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "life",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "mana",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "lifeChances",
            type: "uint32",
          },
        ],
        internalType: "struct IStatController.ChangeableStats",
        name: "stats",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "destroy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "itemIds",
        type: "uint256[]",
      },
      {
        internalType: "uint8[]",
        name: "itemSlots",
        type: "uint8[]",
      },
    ],
    name: "equip",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "equippedOn",
    outputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "genAttackInfo",
    outputs: [
      {
        components: [
          {
            internalType: "enum IItemController.AttackType",
            name: "aType",
            type: "uint8",
          },
          {
            internalType: "int32",
            name: "min",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "max",
            type: "int32",
          },
          {
            components: [
              {
                internalType: "int32",
                name: "strength",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "dexterity",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "vitality",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "energy",
                type: "int32",
              },
            ],
            internalType: "struct IStatController.CoreAttributes",
            name: "attributeFactors",
            type: "tuple",
          },
        ],
        internalType: "struct IItemController.AttackInfo",
        name: "info",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "genAttributeInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint8[]",
            name: "ids",
            type: "uint8[]",
          },
          {
            internalType: "int32[]",
            name: "mins",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "maxs",
            type: "int32[]",
          },
          {
            internalType: "uint32[]",
            name: "chances",
            type: "uint32[]",
          },
        ],
        internalType: "struct IItemController.ItemGenerateInfo",
        name: "info",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "genCasterAttributeInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint8[]",
            name: "ids",
            type: "uint8[]",
          },
          {
            internalType: "int32[]",
            name: "mins",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "maxs",
            type: "int32[]",
          },
          {
            internalType: "uint32[]",
            name: "chances",
            type: "uint32[]",
          },
        ],
        internalType: "struct IItemController.ItemGenerateInfo",
        name: "info",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "genTargetAttributeInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint8[]",
            name: "ids",
            type: "uint8[]",
          },
          {
            internalType: "int32[]",
            name: "mins",
            type: "int32[]",
          },
          {
            internalType: "int32[]",
            name: "maxs",
            type: "int32[]",
          },
          {
            internalType: "uint32[]",
            name: "chances",
            type: "uint32[]",
          },
        ],
        internalType: "struct IItemController.ItemGenerateInfo",
        name: "info",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "incBrokenItemFragility",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "isAllowedToTransfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "itemAttackInfo",
    outputs: [
      {
        components: [
          {
            internalType: "enum IItemController.AttackType",
            name: "aType",
            type: "uint8",
          },
          {
            internalType: "int32",
            name: "min",
            type: "int32",
          },
          {
            internalType: "int32",
            name: "max",
            type: "int32",
          },
          {
            components: [
              {
                internalType: "int32",
                name: "strength",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "dexterity",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "vitality",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "energy",
                type: "int32",
              },
            ],
            internalType: "struct IStatController.CoreAttributes",
            name: "attributeFactors",
            type: "tuple",
          },
        ],
        internalType: "struct IItemController.AttackInfo",
        name: "info",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "itemAttributes",
    outputs: [
      {
        internalType: "int32[]",
        name: "values",
        type: "int32[]",
      },
      {
        internalType: "uint8[]",
        name: "ids",
        type: "uint8[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "idx",
        type: "uint256",
      },
    ],
    name: "itemByIndex",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "itemControllerHelper",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "itemFragility",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "itemInfo",
    outputs: [
      {
        components: [
          {
            internalType: "enum IItemController.ItemRarity",
            name: "rarity",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "augmentationLevel",
            type: "uint8",
          },
          {
            internalType: "uint16",
            name: "durability",
            type: "uint16",
          },
        ],
        internalType: "struct IItemController.ItemInfo",
        name: "info",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "itemMeta",
    outputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "itemMetaType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "itemLevel",
            type: "uint8",
          },
          {
            internalType: "enum IItemController.ItemType",
            name: "itemType",
            type: "uint8",
          },
          {
            internalType: "uint16",
            name: "baseDurability",
            type: "uint16",
          },
          {
            internalType: "uint8",
            name: "defaultRarity",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "manaCost",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "minRandomAttributes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "maxRandomAttributes",
            type: "uint8",
          },
          {
            components: [
              {
                internalType: "int32",
                name: "strength",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "dexterity",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "vitality",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "energy",
                type: "int32",
              },
            ],
            internalType: "struct IStatController.CoreAttributes",
            name: "requirements",
            type: "tuple",
          },
        ],
        internalType: "struct IItemController.ItemMeta",
        name: "meta",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "itemsLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "mint",
    outputs: [
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "packedItemMetaData",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "bool",
        name: "reduceDurabilityAllSkills",
        type: "bool",
      },
    ],
    name: "reduceDurability",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "uint8",
                name: "itemMetaType",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "itemLevel",
                type: "uint8",
              },
              {
                internalType: "enum IItemController.ItemType",
                name: "itemType",
                type: "uint8",
              },
              {
                internalType: "uint16",
                name: "baseDurability",
                type: "uint16",
              },
              {
                internalType: "uint8",
                name: "defaultRarity",
                type: "uint8",
              },
              {
                internalType: "uint32",
                name: "manaCost",
                type: "uint32",
              },
              {
                internalType: "uint8",
                name: "minRandomAttributes",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "maxRandomAttributes",
                type: "uint8",
              },
              {
                components: [
                  {
                    internalType: "int32",
                    name: "strength",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "dexterity",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "vitality",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "energy",
                    type: "int32",
                  },
                ],
                internalType: "struct IStatController.CoreAttributes",
                name: "requirements",
                type: "tuple",
              },
            ],
            internalType: "struct IItemController.ItemMeta",
            name: "itemMeta",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "augmentToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "augmentAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "mins",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "maxs",
                type: "int32[]",
              },
              {
                internalType: "uint32[]",
                name: "chances",
                type: "uint32[]",
              },
            ],
            internalType: "struct IItemController.ItemGenerateInfo",
            name: "commonAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "values",
                type: "int32[]",
              },
            ],
            internalType: "struct IGOC.AttributeGenerateInfo",
            name: "consumableAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint32",
                name: "level",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "experience",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "life",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "mana",
                type: "uint32",
              },
              {
                internalType: "uint32",
                name: "lifeChances",
                type: "uint32",
              },
            ],
            internalType: "struct IStatController.ChangeableStats",
            name: "consumableStats",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "mins",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "maxs",
                type: "int32[]",
              },
              {
                internalType: "uint32[]",
                name: "chances",
                type: "uint32[]",
              },
            ],
            internalType: "struct IItemController.ItemGenerateInfo",
            name: "casterAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8[]",
                name: "ids",
                type: "uint8[]",
              },
              {
                internalType: "int32[]",
                name: "mins",
                type: "int32[]",
              },
              {
                internalType: "int32[]",
                name: "maxs",
                type: "int32[]",
              },
              {
                internalType: "uint32[]",
                name: "chances",
                type: "uint32[]",
              },
            ],
            internalType: "struct IItemController.ItemGenerateInfo",
            name: "targetAttributes",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "enum IItemController.AttackType",
                name: "aType",
                type: "uint8",
              },
              {
                internalType: "int32",
                name: "min",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "max",
                type: "int32",
              },
              {
                components: [
                  {
                    internalType: "int32",
                    name: "strength",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "dexterity",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "vitality",
                    type: "int32",
                  },
                  {
                    internalType: "int32",
                    name: "energy",
                    type: "int32",
                  },
                ],
                internalType: "struct IStatController.CoreAttributes",
                name: "attributeFactors",
                type: "tuple",
              },
            ],
            internalType: "struct IItemController.AttackInfo",
            name: "genAttackInfo",
            type: "tuple",
          },
          {
            internalType: "uint256",
            name: "consumableActionMask",
            type: "uint256",
          },
        ],
        internalType: "struct IItemController.RegisterItemParams",
        name: "info",
        type: "tuple",
      },
    ],
    name: "registerItem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        components: [
          {
            internalType: "uint8",
            name: "itemMetaType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "itemLevel",
            type: "uint8",
          },
          {
            internalType: "enum IItemController.ItemType",
            name: "itemType",
            type: "uint8",
          },
          {
            internalType: "uint16",
            name: "baseDurability",
            type: "uint16",
          },
          {
            internalType: "uint8",
            name: "defaultRarity",
            type: "uint8",
          },
          {
            internalType: "uint32",
            name: "manaCost",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "minRandomAttributes",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "maxRandomAttributes",
            type: "uint8",
          },
          {
            components: [
              {
                internalType: "int32",
                name: "strength",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "dexterity",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "vitality",
                type: "int32",
              },
              {
                internalType: "int32",
                name: "energy",
                type: "int32",
              },
            ],
            internalType: "struct IStatController.CoreAttributes",
            name: "requirements",
            type: "tuple",
          },
        ],
        internalType: "struct IItemController.ItemMeta",
        name: "meta_",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "packedItemMetaData_",
        type: "bytes",
      },
    ],
    name: "registerOtherItem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "removeItem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "consumedItemId",
        type: "uint256",
      },
    ],
    name: "repairDurability",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "consumedItem",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "consumedItemId",
        type: "uint256",
      },
    ],
    name: "repairFragility",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "score",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "helper",
        type: "address",
      },
    ],
    name: "setItemControllerHelper",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "tokenIds",
        type: "uint256[]",
      },
      {
        internalType: "uint8[]",
        name: "itemSlots",
        type: "uint8[]",
      },
    ],
    name: "takeOff",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "itemSlot",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "destination",
        type: "address",
      },
      {
        internalType: "bool",
        name: "broken",
        type: "bool",
      },
    ],
    name: "takeOffDirectly",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "targetAttributes",
    outputs: [
      {
        internalType: "int32[]",
        name: "values",
        type: "int32[]",
      },
      {
        internalType: "uint8[]",
        name: "ids",
        type: "uint8[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
    ],
    name: "tsFirstAugmentation",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "use",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "useOtherItem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class ItemController__factory {
  static readonly abi = _abi;
  static createInterface(): ItemControllerInterface {
    return new Interface(_abi) as ItemControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): ItemController {
    return new Contract(address, _abi, runner) as unknown as ItemController;
  }
}
