/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  ReinforcementController,
  ReinforcementControllerInterface,
} from "../ReinforcementController";

const _abi = [
  {
    inputs: [],
    name: "AlreadyStaked",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroTokenId",
        type: "uint256",
      },
    ],
    name: "ErrorHeroIsDead",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
    ],
    name: "ErrorHeroIsNotRegistered",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDungeonFactory",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotHeroController",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ErrorNotOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorOnlyEoa",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "GuildHasNoShelter",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "helper",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    name: "GuildHelperNotAvailable",
    type: "error",
  },
  {
    inputs: [],
    name: "GuildReinforcementCooldownPeriod",
    type: "error",
  },
  {
    inputs: [],
    name: "HelperNotAvailableInGivenBiome",
    type: "error",
  },
  {
    inputs: [],
    name: "HeroInDungeon",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NotBusyGuildHelper",
    type: "error",
  },
  {
    inputs: [],
    name: "NotGuildMember",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "NotStaked",
    type: "error",
  },
  {
    inputs: [],
    name: "NotStakedInGuild",
    type: "error",
  },
  {
    inputs: [],
    name: "PvpStaked",
    type: "error",
  },
  {
    inputs: [],
    name: "SandboxModeNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "ShelterHasNotEnoughLevelForReinforcement",
    type: "error",
  },
  {
    inputs: [],
    name: "StakeHeroNotStats",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "TooHighValue",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroAmount",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "itemId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "ClaimedItem",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "ClaimedToken",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "GuildHeroAsked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "GuildHeroReleased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "GuildHeroStaked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "GuildHeroWithdrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "GuildNftRewardRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountAdded",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "GuildTokenRewardRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "hitsLast24h",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fixedFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "helperRewardAmount",
        type: "uint256",
      },
    ],
    name: "HeroAskV2",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "biome",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "rewardAmount",
        type: "uint256",
      },
    ],
    name: "HeroStakedV2",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "HeroWithdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "NftRewardRegistered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountAdded",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalAmount",
        type: "uint256",
      },
    ],
    name: "TokenRewardRegistered",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "helper",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    name: "askGuildHero",
    outputs: [
      {
        internalType: "int32[]",
        name: "attributes",
        type: "int32[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "helper",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    name: "askHeroV2",
    outputs: [
      {
        internalType: "int32[]",
        name: "attributes",
        type: "int32[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "busyGuildHelperOf",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "claimAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "countNft",
        type: "uint256",
      },
    ],
    name: "claimNft",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "earned",
    outputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "amounts",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "nfts",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "ids",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getConfigV2",
    outputs: [
      {
        internalType: "uint32",
        name: "minNumberHits",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "maxNumberHits",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "lowDivider",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "highDivider",
        type: "uint32",
      },
      {
        internalType: "uint8",
        name: "limitLevel",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "hitsLast24h",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getFeeAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "feeAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getHitsNumberPerLast24Hours",
    outputs: [
      {
        internalType: "uint256",
        name: "hitsLast24h",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getLastWindowsV2",
    outputs: [
      {
        components: [
          {
            internalType: "uint24[8]",
            name: "baskets",
            type: "uint24[8]",
          },
          {
            internalType: "uint24",
            name: "basketValue",
            type: "uint24",
          },
          {
            internalType: "uint48",
            name: "basketIndex",
            type: "uint48",
          },
        ],
        internalType: "struct IReinforcementController.LastWindowsV2",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "getStakedGuild",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "heroInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "score",
            type: "uint256",
          },
          {
            internalType: "uint8",
            name: "fee",
            type: "uint8",
          },
          {
            internalType: "uint64",
            name: "stakeTs",
            type: "uint64",
          },
        ],
        internalType: "struct IReinforcementController.HeroInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "heroInfoV2",
    outputs: [
      {
        components: [
          {
            internalType: "uint8",
            name: "biome",
            type: "uint8",
          },
          {
            internalType: "uint64",
            name: "stakeTs",
            type: "uint64",
          },
          {
            internalType: "uint128",
            name: "rewardAmount",
            type: "uint128",
          },
        ],
        internalType: "struct IReinforcementController.HeroInfoV2",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "heroesByBiomeV2",
    outputs: [
      {
        internalType: "address[]",
        name: "helpers",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "helperIds",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "heroesByBiomeV2ByIndex",
    outputs: [
      {
        internalType: "address",
        name: "helper",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "heroesByBiomeV2Length",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "isStaked",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "isStakedV1",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "isStakedV2",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "lastGuildHeroWithdrawTs",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC721Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "registerNftReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "registerTokenReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "helperHeroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "helperHeroTokenId",
        type: "uint256",
      },
    ],
    name: "releaseGuildHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint32",
            name: "minNumberHits",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "maxNumberHits",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "lowDivider",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "highDivider",
            type: "uint32",
          },
          {
            internalType: "uint8",
            name: "levelLimit",
            type: "uint8",
          },
        ],
        internalType: "struct IReinforcementController.ConfigReinforcementV2",
        name: "config",
        type: "tuple",
      },
    ],
    name: "setConfigV2",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "stakeGuildHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rewardAmount",
        type: "uint256",
      },
    ],
    name: "stakeHeroV2",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "stakedGuildHelperByIndex",
    outputs: [
      {
        internalType: "address",
        name: "hero",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "busyInGuildId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "stakedGuildHelpersLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "toHelperRatio",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "withdrawGuildHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "withdrawHero",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "heroToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "heroId",
        type: "uint256",
      },
    ],
    name: "withdrawHeroV2",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class ReinforcementController__factory {
  static readonly abi = _abi;
  static createInterface(): ReinforcementControllerInterface {
    return new Interface(_abi) as ReinforcementControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): ReinforcementController {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as ReinforcementController;
  }
}
